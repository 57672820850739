import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";

import { Box } from "@mui/system";
import { Button, Grid, Stack } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import SearchBox from "./SearchBox";
import SearchList from "./SearchList";

import useAxios from "../../../hooks/useAxios";
import { copyData } from "../../../utils/copy";
import { __searchLine, __searchLineClient } from "../../../api/sim";

const Search = ({ searchActive, setSearchActive, operation, client }) => {
  // client refers to the carrier, so absence of client is the non-admin user
  const COPY_KEYS = [
    "id",
    "iccid",
    "batch_id",
    "status",
    "created_at",
    "last_updated",
  ];

  const [result, setResult] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 100,
    total: 1,
  });
  const [response, call, loading] = useAxios(
    !client ? { ...__searchLineClient } : { ...__searchLine }
  );

  const handleSearch = useCallback(() => {
    const params = {
      search_query: searchValue,
      page_size: pageSize,
      page: currentPage,
      operation: operation,
    };
    if (client) {
      params.client = client;
    }
    if (searchValue && searchValue.length) call({ params });
  }, [searchValue, currentPage, pageSize, operation]);

  const debouncedFetchData = debounce(() => {
    handleSearch();
  }, 500);

  useEffect(() => {
    if (response && response.status) {
      setMetaData({
        page: response.data.page,
        page_size: response.data.page_size,
        total: response.data.total,
      });
      setResult(response.data.data);
      setSearchActive(true);
    }
  }, [response]);

  useEffect(() => {
    debouncedFetchData();
  }, [currentPage, pageSize]);

  const handleCancelSearch = () => {
    setSearchValue("");
    setSearchActive(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container justifyContent="flex-start" spacing={2}>
        <Grid item>
          <Stack sx={{ mb: "5px" }}>
            <SearchBox
              searchValue={searchValue}
              handleSearchValueChange={(e) => setSearchValue(e.target.value)}
              clearSearch={handleCancelSearch}
              submit={debouncedFetchData}
            />
          </Stack>
        </Grid>
      </Grid>
      {searchActive && (
        <>
          <Grid container justifyContent="flex-start" spacing={2} mb={1}>
            <Grid item>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  onClick={() => copyData(result, COPY_KEYS)}
                >
                  <ContentCopyIcon sx={{ mr: "5px" }} /> Copy Data
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <SearchList
            data={result}
            meta={metaData}
            loading={loading}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        </>
      )}
    </Box>
  );
};

export default Search;
