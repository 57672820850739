import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import UserForm from "./UserForm";
import Title from "../../common/Title";
import useAxios from "../../../hooks/useAxios";
import useAlert from "../../../hooks/useAlert";
import useAuth from "../../../hooks/useAuth";
import { __createUser } from "../../../api/user";

const UserCreate = () => {
  const { user } = useAuth();
  const nav = useNavigate();

  const { setAlertMessage } = useAlert();

  const [createResponse, createCall, createApiLoading] = useAxios(__createUser);

  if (!user.admin) {
    nav("/");
  }

  const handleFormSubmit = (data) => {
    createCall({ data: data });
  };

  useEffect(() => {
    if (!createResponse) return;
    if (createResponse.status) {
      setAlertMessage({ type: "success", message: `Created successfully.` });

      nav(`/user/${createResponse.data.id}`);
    }
  }, [createResponse]);

  if (createApiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <Box
      sx={{ width: "100%" }}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <UserForm
        isUpdate={false}
        handleSubmit={handleFormSubmit}
        loading={createApiLoading}
      />
    </Box>
  );
};

export default UserCreate;
