import React from "react";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";

import DataTable from "./DataTable";
import { getStatusCount } from "../../constants/value";
import useAuth from "../../hooks/useAuth";

const BatchList = ({
  data,
  meta,
  client,
  loading,
  nav_base,
  handleSort,
  changeNumber,
  disableRowClick,
  handlePageChange,
  handleColumnClick,
  handleFilterChange,
  handlePageSizeChange,
  ...props
}) => {
  const { user } = useAuth();
  const nav = useNavigate();
  const get_nav_path = (params) => `batch/${params.row.batch_id}`;
  const handleRowClick = (params) => nav(get_nav_path(params));
  const updatedData = data.reduce((acc, val) => {
    const updatedVal = {
      id: val.batch_id,
      ...val,
      ...getStatusCount(val.status, changeNumber),
    };
    acc.push(updatedVal);

    return acc;
  }, []);

  const CellWrapper = (props) => (
    <div style={{ width: "100%" }}>
      <Link
        className="cell-link"
        underline="none"
        to={get_nav_path(props.params)}
      >
        {props.children}
      </Link>
    </div>
  );

  const columns = [
    {
      field: "batch_id",
      headerName: "Batch ID",
      flex: 3,
      minWidth: 300,
      sortable: false,
      renderCell: (params) => (
        <CellWrapper params={params}>
          <div>{params.row.batch_id}</div>
        </CellWrapper>
      ),
    },
    // {
    //   field: "client",
    //   headerName: "Client",
    //   minWidth: 120,
    //   flex: 2,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <CellWrapper params={params}>
    //       <div>{params.row.client ? params.row.client.toUpperCase() : "-"}</div>
    //     </CellWrapper>
    //   ),
    // },
    {
      field: "success",
      headerName: "Success",
      minWidth: 120,
      flex: 2,
      sortable: false,
      renderCell: (params) => (
        <CellWrapper params={params}>
          <div>{params.row.success}</div>
        </CellWrapper>
      ),
    },
    {
      field: "pending",
      headerName: "Pending",
      minWidth: 120,
      flex: 2,
      sortable: false,
      renderCell: (params) => (
        <CellWrapper params={params}>
          <div>{params.row.pending}</div>
        </CellWrapper>
      ),
    },
    {
      field: "error",
      headerName: "Error",
      minWidth: 120,
      flex: 2,
      sortable: false,
      renderCell: (params) => (
        <CellWrapper params={params}>
          <div>{params.row.error}</div>
        </CellWrapper>
      ),
    },
    {
      field: "created_at",
      headerName: "Created At",
      minWidth: 200,
      filterable: false,
      flex: 3,
      sortable: false,
      renderCell: (params) => (
        <CellWrapper params={params}>
          <div>
            {params.row.created_at &&
              `${format(new Date(params.row.created_at), "MMMM d, y hh:mm a")}`}
          </div>
        </CellWrapper>
      ),
    },
    {
      field: "last_updated",
      headerName: "Last Updated",
      minWidth: 200,
      filterable: false,
      flex: 3,
      sortable: false,
      renderCell: (params) => (
        <CellWrapper params={params}>
          <div>
            {params.row.last_updated &&
              `${format(
                new Date(params.row.last_updated),
                "MMMM d, y hh:mm a"
              )}`}
          </div>
        </CellWrapper>
      ),
    },
  ];

  if (user.admin) {
    columns.push({
      field: "user_id",
      headerName: "User ID",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <CellWrapper params={params}>
          <div>{params.row.user_id}</div>
        </CellWrapper>
      ),
    });
  }

  return (
    <DataTable
      disableColumnFilter
      loading={loading}
      data={updatedData}
      columns={
        client ? columns.map((val) => ({ ...val, sortable: true })) : columns
      }
      metaData={meta}
      handleRowClick={disableRowClick ? null : handleRowClick}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      filterMode={client ? "client" : "server"}
      client={client}
      {...props}
    />
  );
};

export default BatchList;
