import debounce from "lodash.debounce";
import fileDownload from "js-file-download";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import Title from "../../../common/Title";
import UnpauseList from "./UnpauseList";
import TerminateBatch from "../../../common/TerminateBatch";

import useAxios from "../../../../hooks/useAxios";
import { copyData } from "../../../../utils/copy";
import { CLIENTS } from "../../../../constants/value";
import { __export, __getUnpause, __retryOperation } from "../../../../api/sim";
import useAlert from "../../../../hooks/useAlert";

const UnpauseBatch = () => {
  const { batch_id } = useParams();
  const [data, setData] = useState([]);
  const COPY_KEYS = [
    "id",
    "batch_id",
    "iccid",
    "status",
    "plan",
    "plan_id",
    "last_updated",
  ];

  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 20000,
    total: 1,
  });
  const { setAlertMessage } = useAlert();
  const [retryResponse, retryCall, retryLoading] = useAxios({
    ...__retryOperation(batch_id),
  });
  const [response, call, loading] = useAxios({ ...__getUnpause });
  const [exportResponse, exportCall, exportLoading] = useAxios({ ...__export });

  useEffect(() => {
    if (exportResponse && exportResponse.status) {
      fileDownload(exportResponse.data, `unpause_export-${batch_id}.csv`);
    }
  }, [exportResponse]);

  const debouncedFetchData = debounce((query) => {
    call(query);
  }, 500);

  useEffect(() => {
    debouncedFetchData({
      params: {
        client: CLIENTS.ultra,
        page_size: 20000,
        batch_id: batch_id,
      },
    });

    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setMetaData({
        page: response.data.page,
        page_size: response.data.page_size,
        total: response.data.total,
      });
      setData(response.data.data);
    }
  }, [response]);

  useEffect(() => {
    if (retryResponse && retryResponse.status) {
      setAlertMessage({
        type: "success",
        message: "Unpause retry queued",
      });
    }
  }, [retryResponse]);

  return (
    <Box sx={{ width: "100%" }}>
      <Title
        variant="h5"
        content={`Unpause Batch ID: ${batch_id}`}
        gutterBottom
      />
      {response && (
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item sx={{ mb: "5px" }}>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="primary"
                disabled={loading || exportLoading}
                onClick={() =>
                  exportCall({
                    responseType: "blob",
                    params: {
                      action: "unpause",
                      batch_id: batch_id,
                    },
                  })
                }
              >
                <FileDownloadIcon sx={{ mr: "5px" }} /> Export
              </Button>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="secondary"
                disabled={loading || exportLoading}
                onClick={() => copyData(data, COPY_KEYS)}
              >
                <ContentCopyIcon sx={{ mr: "5px" }} /> Copy Data
              </Button>
            </Stack>
          </Grid>
          <Grid item sx={{ mb: "10px" }}>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="primary"
                disabled={loading || exportLoading}
                onClick={() =>
                  retryCall({
                    params: {
                      client: "ultra",
                      operation: "unpause",
                    },
                  })
                }
              >
                Retry
              </Button>
            </Stack>
          </Grid>
          <Grid item sx={{ mb: "10px" }}>
            <TerminateBatch batch_id={batch_id} />
          </Grid>
        </Grid>
      )}
      {response ? (
        <>
          <UnpauseList
            data={data}
            loading={loading}
            meta={{ ...metaData, page_size: 100 }}
            client={true}
            handleColumnClick={(key) => copyData(data, [key])}
          />
        </>
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default UnpauseBatch;
