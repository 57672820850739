import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import LockIcon from "@mui/icons-material/Lock";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Box, Button, InputLabel, OutlinedInput } from "@mui/material";

import Title from "../../common/Title";
import useAuth from "../../../hooks/useAuth";
import useAxios from "../../../hooks/useAxios";
import useAlert from "../../../hooks/useAlert";
import InputFieldAutoComplete from "../../common/InputFieldAutoComplete";
import { __getPlans } from "../../../api/sim";
import { CLIENTS } from "../../../constants/value";

const UserForm = ({ isUpdate, handleSubmit, initialValues, loading }) => {
  const { user } = useAuth();
  const nav = useNavigate();

  const initialData = {
    username: "",
    password: "",
    api_key: "",
    sms_portal_user_id: null,
    telit_plan_id: null,
    ultra_plan_id: null,
    max_number_change: null,
  };

  if (!user.admin) {
    nav("/");
  }

  const [ultraPlans, setUltraPlans] = useState([]);
  const [telitPlans, setTelitPlans] = useState([]);
  const [formData, setFormData] = useState(null);

  const createSchema = yup.object({
    username: yup.string("username").required("Username is required"),
    password: yup.string("password").required("Password is required").min(8),
    api_key: yup.string("api_key").required("Api key is required"),
    sms_portal_user_id: yup.number("sms_portal_user_id").required(),
    telit_plan_id: yup.number("telit_plan_id").nullable(),
    ultra_plan_id: yup.number("ultra_plan_id").required(),
    max_number_change: yup.number("max_number_change").optional().nullable(),
  });

  const updateSchema = yup.object({
    api_key: yup.string("api_key").required("Api key is required"),
    sms_portal_user_id: yup.number("sms_portal_user_id").required(),
    telit_plan_id: yup.number("telit_plan_id").nullable(),
    ultra_plan_id: yup.number("ultra_plan_id").required(),
    max_number_change: yup.number("max_number_change").optional().nullable(),
  });

  const [ultraPlanResponse, ultraPlanCall, ultraPlanLoading] = useAxios({
    ...__getPlans,
  });
  const [telitPlanResponse, telitPlanCall, telitPlanLoading] = useAxios({
    ...__getPlans,
  });

  const validationSchema = isUpdate ? updateSchema : createSchema;

  const formik = useFormik({
    initialValues:
      initialValues && Object.keys(initialValues).length
        ? { ...initialValues }
        : { ...initialData },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        if (
          Object.keys(validationSchema.fields).includes(key) &&
          values[key] !== null
        ) {
          obj[key] = values[key];
        }

        if (key === "max_number_change" && values[key] === "") {
          obj.max_number_change = null;
        }
        return obj;
      }, {});

      setFormData(requestData);
    },
    validateOnChange: true,
  });

  useEffect(() => {
    if (formData) {
      handleSubmit(formData);
      if (!loading) {
        formik.setSubmitting(false);
      }
    }
  }, [formData]);

  useEffect(() => {
    if (telitPlanResponse && telitPlanResponse.status) {
      setTelitPlans(telitPlanResponse.data);
    }
  }, [telitPlanResponse]);

  useEffect(() => {
    if (ultraPlanResponse && ultraPlanResponse.status) {
      setUltraPlans(ultraPlanResponse.data);
    }
  }, [ultraPlanResponse]);

  useEffect(() => {
    telitPlanCall({ params: { client: CLIENTS.telit } });
    ultraPlanCall({ params: { client: CLIENTS.ultra } });
  }, []);

  const generatePassword = () => {
    const length = 20;
    const charset =
      "0123456789abcdefghijklmnopqrstuvwxyz!ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    formik.setFieldValue("api_key", retVal);
  };

  if (loading || telitPlanLoading || ultraPlanLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <>
      <Box
        sx={{ width: "100%", mb: 5 }}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Title
          variant="h5"
          content={`${isUpdate ? "Update" : "Create"} User`}
          sx={{ mb: 3 }}
          gutterBottom
        />
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
          maxWidth={350}
        >
          <Grid item xs={12} style={{ width: 350 }}>
            <FormControl
              error={
                formik.touched.username && Boolean(formik.errors.username)
                  ? true
                  : false
              }
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="username">Username</InputLabel>
              <OutlinedInput
                name="username"
                label="Username"
                id="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                disabled={isUpdate}
              />
              {formik.touched.username && Boolean(formik.errors.username) ? (
                <FormHelperText id="username-error-text">
                  {formik.errors.username}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {!isUpdate && (
            <Grid item xs={12} minWidth={350}>
              <FormControl
                error={Boolean(formik.errors.password) ? true : false}
                sx={{ width: "100%" }}
              >
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  name="password"
                  label="Password"
                  id="password"
                  value={formik.values.password}
                  disabled={isUpdate}
                  onChange={formik.handleChange}
                />
                {Boolean(formik.errors.password) ? (
                  <FormHelperText id="password-error-text">
                    {formik.errors.password}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} style={{ width: 350 }}>
            <FormControl
              error={
                formik.touched.api_key && Boolean(formik.errors.api_key)
                  ? true
                  : false
              }
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="api_key">Api Key</InputLabel>
              <OutlinedInput
                name="api_key"
                label="Api Key"
                id="api_key"
                value={formik.values.api_key || ""}
                onChange={formik.handleChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={generatePassword}
              >
                Generate Api Key
                <LockIcon />
              </Button>
              {formik.touched.api_key && Boolean(formik.errors.api_key) ? (
                <FormHelperText id="api_key-error-text">
                  {formik.errors.api_key}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} style={{ width: 350 }}>
            <FormControl
              error={
                formik.touched.sms_portal_user_id &&
                Boolean(formik.errors.sms_portal_user_id)
                  ? true
                  : false
              }
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="sms_portal_user_id">
                Sms portal user ID
              </InputLabel>
              <OutlinedInput
                name="sms_portal_user_id"
                label="Sms portal user ID"
                id="sms_portal_user_id"
                value={formik.values.sms_portal_user_id}
                onChange={formik.handleChange}
              />
              {formik.touched.sms_portal_user_id &&
              Boolean(formik.errors.sms_portal_user_id) ? (
                <FormHelperText id="sms_portal_user_id-error-text">
                  {formik.errors.sms_portal_user_id}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} minWidth={350}>
            <FormControl
              error={
                formik.touched.ultra_plan_id &&
                Boolean(formik.errors.ultra_plan_id)
                  ? true
                  : false
              }
              sx={{ width: "100%" }}
            >
              <InputFieldAutoComplete
                isSelect
                options={[...ultraPlans.map((group) => group.id)]}
                name="ultra_plan_id"
                label="Ultra Plan"
                id="ultra_plan_id"
                getOptionLabel={(option) =>
                  ultraPlans.reduce((label, val) => {
                    if (`${val.id}` === `${option}`) return val.name;
                    return label;
                  }, "")
                }
                value={formik.values.ultra_plan_id}
                onChange={(_, val) =>
                  formik.setFieldValue("ultra_plan_id", val)
                }
              />
              {formik.touched.ultra_plan_id &&
              Boolean(formik.errors.ultra_plan_id) ? (
                <FormHelperText id="ultra_plan_id-error-text">
                  {formik.errors.ultra_plan_id}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} minWidth={350}>
            <FormControl
              error={
                formik.touched.telit_plan_id &&
                Boolean(formik.errors.telit_plan_id)
                  ? true
                  : false
              }
              sx={{ width: "100%" }}
            >
              <InputFieldAutoComplete
                isSelect
                options={[null, ...telitPlans.map((group) => group.id)]}
                name="telit_plan_id"
                label="Telit Plan"
                id="telit_plan_id"
                getOptionLabel={(option) =>
                  telitPlans.reduce((label, val) => {
                    if (option === null) return "No Plan";
                    if (`${val.id}` === `${option}`) return val.name;
                    return label;
                  }, "")
                }
                value={formik.values.telit_plan_id}
                onChange={(_, val) =>
                  formik.setFieldValue("telit_plan_id", val)
                }
              />
              {formik.touched.telit_plan_id &&
              Boolean(formik.errors.telit_plan_id) ? (
                <FormHelperText id="telit_plan_id-error-text">
                  {formik.errors.telit_plan_id}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ width: 350 }}>
            <FormControl
              error={
                formik.touched.max_number_change &&
                Boolean(formik.errors.max_number_change)
                  ? true
                  : false
              }
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="max_number_change">
                Max Number Change
              </InputLabel>
              <OutlinedInput
                name="max_number_change"
                label="Max Number Change"
                id="max_number_change"
                type="number"
                value={formik.values.max_number_change}
                onChange={formik.handleChange}
              />
              {formik.touched.max_number_change &&
              Boolean(formik.errors.max_number_change) ? (
                <FormHelperText id="max_number_change-error-text">
                  {formik.errors.max_number_change}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid
            container
            sx={{ mt: 3 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <LoadingButton
                style={{ marginRight: "5px" }}
                disabled={Object.keys(formik.errors).length}
                onClick={() =>
                  !Object.keys(formik.errors).length &&
                  !loading &&
                  !formik.isSubmitting &&
                  formik.submitForm()
                }
                size="large"
                loading={loading}
                variant="contained"
              >
                Update
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                style={{ marginRight: "5px" }}
                size="large"
                onClick={() => nav("/user")}
                loading={loading}
                variant="contained"
              >
                Cancel
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UserForm;
