import { format } from "date-fns";
import debounce from "lodash.debounce";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";

import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Stack } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/Download";

import Title from "../../../common/Title";
import DataTable from "../../../common/DataTable";

import useAlert from "../../../../hooks/useAlert";
import useAxios from "../../../../hooks/useAxios";
import { __getZipCheckList, __getZipCheckFile } from "../../../../api/sim";

const ZipCheckList = () => {
  const [data, setData] = useState([]);
  const { setAlertMessage } = useAlert();
  const nav = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 100,
    total: 1,
  });
  const [response, call, loading] = useAxios({ ...__getZipCheckList });
  const [fileResponse, fileCall, fileLoading] = useAxios({
    ...__getZipCheckFile,
  });

  const debouncedFetchData = debounce((query) => {
    call(query);
  }, 500);

  useEffect(() => {
    debouncedFetchData({
      params: {
        page: currentPage,
        page_size: pageSize,
      },
    });

    return () => {
      debouncedFetchData.cancel();
    };
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (response && response.status) {
      setMetaData({
        page: response.data.page,
        page_size: response.data.page_size,
        total: response.data.total,
      });
      setData(response.data.data);
    }
  }, [response]);

  useEffect(() => {
    if (fileResponse && fileResponse.status) {
      fileDownload(
        fileResponse.data,
        `zip-eligibility-${new Date().toISOString()}.csv`
      );
    }
  }, [fileResponse]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setCurrentPage(1);
    setPageSize(newPageSize);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1, sortable: false },
    {
      field: "filename",
      headerName: "Filename",
      minWidth: 200,
      flex: 3,
      sortable: false,
    },
    { field: "status", headerName: "Status", flex: 2, sortable: false },
    {
      field: "created_at",
      headerName: "Created",
      minWidth: 200,
      flex: 3,
      sortable: false,
      valueGetter: (params) =>
        params.row.created_at &&
        `${format(new Date(params.row.created_at), "MMMM d, y hh:mm a")}`,
    },
    {
      field: "last_updated",
      headerName: "Updated",
      minWidth: 200,
      flex: 3,
      sortable: false,
      valueGetter: (params) =>
        params.row.created_at &&
        `${format(new Date(params.row.last_updated), "MMMM d, y hh:mm a")}`,
    },
    {
      field: "action",
      headerName: "Download",
      minWidth: 100,
      flex: 2,
      renderCell: (params) =>
        params.row.status === "completed" ? (
          <FileDownloadIcon
            color="primary"
            onClick={() =>
              fileCall({
                responseType: "blob",
                url: `sim/zip_check/${params.row.id}`,
              })
            }
          />
        ) : null,
      sortable: false,
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content="Zip Check History" />
      <Grid container justifyContent="flex-start" spacing={2}>
        <Grid item sx={{ mb: "5px" }}>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="primary"
              disabled={loading || fileLoading}
              onClick={() => nav("form")}
            >
              Check Zips
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <DataTable
        disableColumnFilter
        loading={loading}
        data={data}
        columns={columns}
        metaData={metaData}
        handleRowClick={null}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
      />
    </Box>
  );
};

export default ZipCheckList;
