import React from "react";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchBox({
  searchValue,
  handleSearchValueChange,
  submit,
  clearSearch,
}) {
  const handleClick = () => {
    submit();
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submit();
    }
  };

  return (
    <Paper
      component="form"
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 300 }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        onChange={handleSearchValueChange}
        onSubmit={(e) => e.preventDefault()}
        onKeyDown={handleEnter}
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        value={searchValue}
      />
      <IconButton
        disabled={!searchValue || searchValue.length <= 3}
        onClick={handleClick}
        sx={{ p: "10px" }}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
      <IconButton
        disabled={!searchValue}
        onClick={clearSearch}
        sx={{ p: "10px" }}
        aria-label="search"
      >
        <CancelIcon />
      </IconButton>
    </Paper>
  );
}
