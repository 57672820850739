import debounce from "lodash.debounce";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import Title from "../../../common/Title";
import TerminateBatch from "../../../common/TerminateBatch";

import { copyData } from "../../../../utils/copy";
import useAxios from "../../../../hooks/useAxios";
import useAlert from "../../../../hooks/useAlert";
import ClientDeactivateList from "./DeactivateList";
import { isUltraSim } from "../../../../utils/value";
import { CLIENTS } from "../../../../constants/value";
import { __getDeactivate, __export, __checkStatus, __retryOperation } from "../../../../api/sim";

const ClientDeactivateBatch = () => {
  const { batch_id } = useParams();
  const [data, setData] = useState([]);
  const nav = useNavigate();

  const COPY_KEYS = ["id", "batch_id", "iccid", "status", "last_updated"];

  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 20000,
    total: 1,
  });
  const [client, setClient] = useState();
  const [response, call, loading] = useAxios({ ...__getDeactivate });
  const { setAlertMessage } = useAlert();
  const [recheckResponse, recheckCall, recheckLoading] = useAxios({
    ...__checkStatus(batch_id),
  });
  const [exportResponse, exportCall, exportLoading] = useAxios({ ...__export });
  const [retryResponse, retryCall, retryLoading] = useAxios({
    ...__retryOperation(batch_id),
  });

  const debouncedFetchData = debounce((query) => {
    call(query);
  }, 500);

  useEffect(() => {
    debouncedFetchData({
      params: {
        page_size: 20000,
        batch_id: batch_id,
      },
    });

    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setMetaData({
        page: response.data.page,
        page_size: response.data.page_size,
        total: response.data.total,
      });
      setData(response.data.data);

      if (response.data.data && response.data.data.length) {
        setClient(
          isUltraSim(response.data.data[0].iccid)
            ? CLIENTS.ultra
            : CLIENTS.telit
        );
      } else {
        nav("/client/deactivate");
      }
    }
  }, [response]);

  useEffect(() => {
    if (exportResponse && exportResponse.status) {
      fileDownload(exportResponse.data, `deactivate_export-${batch_id}.csv`);
    }
  }, [exportResponse]);

  useEffect(() => {
    if (recheckResponse && recheckResponse.status) {
      setAlertMessage({
        type: "success",
        message: "Deactivation recheck queued",
      });
    }
  }, [recheckResponse]);

  useEffect(() => {
    if (retryResponse && retryResponse.status) {
      setAlertMessage({ type: "success", message: "Activation retry queued" });
    }
  }, [retryResponse]);

  return (
    <Box sx={{ width: "100%" }}>
      <Title
        variant="h5"
        content={`Deactivate Batch ID: ${batch_id}`}
        gutterBottom
      />
      {response && (
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item sx={{ mb: "10px" }}>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="primary"
                disabled={loading || exportLoading}
                onClick={() =>
                  exportCall({
                    responseType: "blob",
                    params: {
                      action: "deactivate",
                      batch_id: batch_id,
                    },
                  })
                }
              >
                <FileDownloadIcon sx={{ mr: "5px" }} /> Export
              </Button>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="secondary"
                disabled={loading || exportLoading}
                onClick={() => copyData(data, COPY_KEYS)}
              >
                <ContentCopyIcon sx={{ mr: "5px" }} /> Copy Data
              </Button>
            </Stack>
          </Grid>
          <Grid item sx={{ mb: "10px" }}>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="primary"
                disabled={loading || exportLoading}
                onClick={() =>
                  retryCall({
                    params: {
                      operation: "deactivate",
                    },
                  })
                }
              >
                Retry
              </Button>
            </Stack>
          </Grid>
          {client === CLIENTS.telit && (
            <Grid item sx={{ mb: "10px" }}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading || exportLoading || recheckLoading}
                  onClick={() =>
                    recheckCall({
                      params: {
                        operation: "deactivate",
                      },
                    })
                  }
                >
                  Recheck Status
                </Button>
              </Stack>
            </Grid>
          )}
          <Grid item sx={{ mb: "10px" }}>
            <TerminateBatch batch_id={batch_id} operation="deactivate" />
          </Grid>
        </Grid>
      )}
      {response ? (
        <>
          <ClientDeactivateList
            data={data}
            loading={loading}
            meta={{ ...metaData, page_size: 100 }}
            client={true}
            handleColumnClick={(key) => copyData(data, [key])}
          />
        </>
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default ClientDeactivateBatch;
