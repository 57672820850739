import React from "react";
import { format } from "date-fns";

import { Chip } from "@mui/material";

import DataTable from "../../../common/DataTable";
import ColumnHeader from "../../../common/ColumnHeader";

import { getChipColor } from "../../../../constants/value";

const TelitSimInfoList = ({
  data,
  meta,
  client,
  loading,
  handleSort,
  disableRowClick,
  handlePageChange,
  handleColumnClick,
  handleFilterChange,
  handlePageSizeChange,
  ...props
}) => {
  const columns = [
    { field: "id", headerName: "ID", flex: 1, sortable: false },
    {
      field: "iccid",
      headerName: "ICCID",
      minWidth: 200,
      flex: 3,
      sortable: false,
      renderHeader: (params) =>
        client ? (
          <ColumnHeader
            label={"ICCID"}
            handleClick={() => handleColumnClick("iccid")}
          />
        ) : (
          "ICCID"
        ),
    },
    {
      field: "msisdn",
      headerName: "MSISDN",
      minWidth: 200,
      flex: 3,
      sortable: false,
      renderHeader: (params) =>
        client ? (
          <ColumnHeader
            label={"MSISDN"}
            handleClick={() => handleColumnClick("msisdn")}
          />
        ) : (
          "MSISDN"
        ),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 2,
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          size="small"
          color={getChipColor(params.row.status)}
        />
      ),
      sortable: false,
      renderHeader: (params) =>
        client ? (
          <ColumnHeader
            label={"Status"}
            handleClick={() => handleColumnClick("status")}
          />
        ) : (
          "Status"
        ),
    },
    {
      field: "plan",
      headerName: "Plan",
      minWidth: 100,
      flex: 2,
      sortable: false,
      renderHeader: (params) =>
        client ? (
          <ColumnHeader
            label={"Plan"}
            handleClick={() => handleColumnClick("plan")}
          />
        ) : (
          "Plan"
        ),
    },
    {
      field: "customer",
      headerName: "Customer",
      minWidth: 150,
      flex: 2,
      sortable: false,
      renderHeader: (params) =>
        client ? (
          <ColumnHeader
            label={"Customer"}
            handleClick={() => handleColumnClick("customer")}
          />
        ) : (
          "Customer"
        ),
    },
    {
      field: "date_activated",
      headerName: "Date Activated",
      minWidth: 200,
      filterable: false,
      flex: 3,
      sortable: false,
      valueGetter: (params) =>
        params.row.date_activated &&
        `${format(new Date(params.row.date_activated), "MMMM d, y hh:mm a")}`,
      renderHeader: (params) =>
        client ? (
          <ColumnHeader
            label={"Date Activated"}
            handleClick={() => handleColumnClick("date_activated")}
          />
        ) : (
          "Date Activated"
        ),
    },
    {
      field: "date_modified",
      headerName: "Date Modified",
      minWidth: 200,
      filterable: false,
      flex: 3,
      sortable: false,
      valueGetter: (params) =>
        params.row.date_modified &&
        `${format(new Date(params.row.date_modified), "MMMM d, y hh:mm a")}`,
      renderHeader: (params) =>
        client ? (
          <ColumnHeader
            label={"Date Modified"}
            handleClick={() => handleColumnClick("date_modified")}
          />
        ) : (
          "MSISDN"
        ),
    },
    {
      field: "created_at",
      headerName: "Created At",
      minWidth: 200,
      filterable: false,
      flex: 3,
      sortable: false,
      valueGetter: (params) =>
        params.row.created_at &&
        `${format(new Date(params.row.created_at), "MMMM d, y hh:mm a")}`,
    },
    {
      field: "last_updated",
      headerName: "Last Updated",
      minWidth: 200,
      filterable: false,
      flex: 3,
      sortable: false,
      valueGetter: (params) =>
        params.row.last_updated &&
        `${format(new Date(params.row.last_updated), "MMMM d, y hh:mm a")}`,
    },
  ];

  return (
    <DataTable
      disableColumnFilter
      loading={loading}
      data={data}
      client={client}
      columns={
        client ? columns.map((val) => ({ ...val, sortable: true })) : columns
      }
      metaData={meta}
      handleRowClick={null}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      {...props}
    />
  );
};

export default TelitSimInfoList;
