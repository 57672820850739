import React from "react";

import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Autocomplete, TextField } from "@mui/material";

import TextInfo from "./TextInfo";

const InputFieldAutoComplete = ({
  id,
  type,
  name,
  label,
  value,
  error,
  options,
  groupBy,
  disabled,
  onChange,
  isSelect,
  isTouched,
  isCheckbox,
  isOptionEqualToValue,
  ...props
}) => {
  return (
    <FormControl
      error={isTouched && Boolean(error) ? true : false}
      sx={{ width: "100%" }}
    >
      {!isCheckbox ? (
        <>
          {!isSelect ? (
            <OutlinedInput
              type={type || ""}
              id={id}
              name={name}
              label={label}
              value={value}
              onChange={onChange}
            />
          ) : (
            <Autocomplete
              id={id}
              name={name}
              value={value}
              onChange={onChange}
              disabled={disabled}
              disablePortal
              options={options}
              renderInput={(params) => <TextField {...params} label={label} />}
              groupBy={groupBy}
              isOptionEqualToValue={isOptionEqualToValue}
              {...props}
            ></Autocomplete>
          )}
        </>
      ) : (
        <></>
      )}
      {isTouched && Boolean(error) ? <TextInfo error text={error} /> : ""}
    </FormControl>
  );
};

export default InputFieldAutoComplete;
