const __activate = {
  method: "POST",
  url: "sim/activate",
};

const __getActivateBatches = {
  method: "GET",
  url: "sim/activate/batches",
};

const __activateTelit = {
  method: "POST",
  url: "sim/activate/telit",
};

const __activateSparq = {
  method: "POST",
  url: "sim/activate/sparq",
};

const __activateCricket = {
  method: "POST",
  url: "sim/activate/cricket",
};

const __activateVerizon = {
  method: "POST",
  url: "sim/activate/verizon",
};

const __activateTmobile = {
  method: "POST",
  url: "sim/activate/tmobile",
};

const __getActivate = {
  method: "GET",
  url: "sim/activate",
};

const __getActivateLog = (id) => ({
  method: "GET",
  url: `sim/activate/${id}`,
});

const __pause = {
  method: "POST",
  url: "sim/pause",
};

const __pauseTelit = {
  method: "POST",
  url: "sim/pause/telit",
};

const __getPause = {
  method: "GET",
  url: "sim/pause",
};

const __getPauseBatches = {
  method: "GET",
  url: "sim/pause/batches",
};

const __getPauseLog = (id) => ({
  method: "GET",
  url: `sim/pause/${id}`,
});

const __deactivate = {
  method: "POST",
  url: "sim/deactivate",
};

const __deactivateTelit = {
  method: "POST",
  url: "sim/deactivate/telit",
};

const __deactivateSparq = {
  method: "POST",
  url: "sim/deactivate/sparq",
};

const __getDeactivate = {
  method: "GET",
  url: "sim/deactivate",
};

const __getDeactivateBatches = {
  method: "GET",
  url: "sim/deactivate/batches",
};

const __getDeactivateLog = (id) => ({
  method: "GET",
  url: `sim/deactivate/${id}`,
});

const __restore = {
  method: "POST",
  url: "sim/restore",
};

const __getRestore = {
  method: "GET",
  url: "sim/restore",
};

const __getRestoreBatches = {
  method: "GET",
  url: "sim/restore/batches",
};

const __getRestoreLog = (id) => ({
  method: "GET",
  url: `sim/restore/${id}`,
});

const __unpause = {
  method: "POST",
  url: "sim/unpause",
};

const __getUnpause = {
  method: "GET",
  url: "sim/unpause",
};

const __getUnpauseBatches = {
  method: "GET",
  url: "sim/unpause/batches",
};

const __getUnpauseLog = (id) => ({
  method: "GET",
  url: `sim/unpause/${id}`,
});

const __changeNumber = {
  method: "POST",
  url: "sim/change_number",
};

const __changeNumberTelit = {
  method: "POST",
  url: "sim/change_number/telit",
};

const __getChangeNumber = {
  method: "GET",
  url: "sim/change_number",
};

const __getChangeNumberBatches = {
  method: "GET",
  url: "sim/change_number/batches",
};

const __getChangeNumberLog = (id) => ({
  method: "GET",
  url: `sim/change_number/${id}`,
});

const __getSimInfo = {
  method: "GET",
  url: "sim/sim_info",
};

const __getSimInfoBatches = {
  method: "GET",
  url: "sim/sim_info/batches",
};

const __fetchSimInfo = {
  method: "POST",
  url: "sim/sim_info",
};

const __fetchSimTelitInfo = {
  method: "POST",
  url: "sim/sim_info/telit",
};

const __export = {
  method: "GET",
  url: "sim/export",
};

const __getPlans = {
  method: "GET",
  url: "sim/plans",
};

const __retryOperation = (batch_id) => ({
  method: "POST",
  url: `sim/retry_failed/${batch_id}`,
});

const __changePlan = {
  method: "POST",
  url: "sim/change_plan",
};

const __getChangePlanBatches = {
  method: "GET",
  url: "sim/change_plan/batches",
};

const __changePlanTelit = {
  method: "POST",
  url: "sim/change_plan/telit",
};

const __getChangePlan = {
  method: "GET",
  url: "sim/change_plan",
};

const __getChangePlanLog = (id) => ({
  method: "GET",
  url: `sim/change_plan/${id}`,
});

const __getRefillBatches = {
  method: "GET",
  url: "sim/refill/batches",
};

const __refillCricket = {
  method: "POST",
  url: "sim/refill/cricket",
};

const __refillVerizon = {
  method: "POST",
  url: "sim/refill/verizon",
};

const __getRefill = {
  method: "GET",
  url: "sim/refill",
};

const __getRefillLog = (id) => ({
  method: "GET",
  url: `sim/refill/${id}`,
});

const __getTelitExportList = {
  method: "GET",
  url: "sim/telit_export",
};

const __getTelitExportFile = {
  method: "GET",
};

const __telit_export = {
  method: "POST",
  url: "sim/sim_info/telit/export",
};

const __getZipCheckList = {
  method: "GET",
  url: "sim/zip_check",
};

const __getZipCheckFile = {
  method: "GET",
};

const __zipCheck = {
  method: "POST",
  url: "sim/sim_info/zip",
};

const __checkStatus = (batch_id) => ({
  method: "POST",
  url: `sim/recheck_status/${batch_id}`,
});

const __terminateProcess = (batch_id) => ({
  method: "POST",
  url: `sim/stop_batch/${batch_id}`,
});

const __getSparqSimGroups = {
  method: "GET",
  url: "sim/sparq_sim_group",
};

const __getCredential = (client) => ({
  method: "GET",
  url: `sim/credentials/${client}`,
});

const __updateCredential = (client) => ({
  method: "PUT",
  url: `sim/credentials/${client}`,
});

const __portin = {
  method: "POST",
  url: "sim/portin",
};

const __getPortinBatches = {
  method: "GET",
  url: "sim/portin/batches",
};

const __portinUltra = {
  method: "POST",
  url: "sim/portin/ultra",
};

const __getPortin = {
  method: "GET",
  url: "sim/portin",
};

const __getPortinLog = (id) => ({
  method: "GET",
  url: `sim/portin/${id}`,
});

const __clientActivate = {
  method: "POST",
  url: "clients/activate",
};

const __clientDeactivate = {
  method: "POST",
  url: "clients/deactivate",
};

const __clientChangeNumber = {
  method: "POST",
  url: "clients/change_number",
};

const __clientSimInfo = {
  method: "POST",
  url: "clients/sim_info",
};

const __getUserHistory = {
  method: "POST",
  url: "sim/user_history",
};

const __reset = {
  method: "POST",
  url: "clients/reset/",
};

const __resetAdminUltra = {
  method: "POST",
  url: "sim/reset/",
};

const __resetAdminTelit = {
  method: "POST",
  url: "sim/reset/telit",
};

const __getResetBatches = {
  method: "GET",
  url: "sim/reset/batches",
};

const __getReset = {
  method: "GET",
  url: "sim/reset",
};

const __setResetComplete = (batch_id) => ({
  method: "POST",
  url: `sim/reset/${batch_id}/complete`,
});

const __setResetFailed = (batch_id) => ({
  method: "POST",
  url: `sim/reset/${batch_id}/failed`,
});

const __searchLineClient = {
  method: "GET",
  url: `clients/search_lines`,
};

const __searchLine = {
  method: "GET",
  url: `sim/search_lines`,
};

export {
  __activate,
  __getActivate,
  __getActivateBatches,
  __activateTelit,
  __activateSparq,
  __activateCricket,
  __activateVerizon,
  __activateTmobile,
  __getActivateLog,
  __pause,
  __pauseTelit,
  __getPause,
  __getPauseBatches,
  __getPauseLog,
  __deactivate,
  __getDeactivate,
  __getDeactivateBatches,
  __deactivateTelit,
  __deactivateSparq,
  __getDeactivateLog,
  __restore,
  __getRestore,
  __getRestoreBatches,
  __getRestoreLog,
  __unpause,
  __getUnpause,
  __getUnpauseBatches,
  __getUnpauseLog,
  __changeNumber,
  __changeNumberTelit,
  __getChangeNumber,
  __getChangeNumberBatches,
  __getChangeNumberLog,
  __getSimInfo,
  __getSimInfoBatches,
  __fetchSimInfo,
  __export,
  __getPlans,
  __retryOperation,
  __changePlan,
  __getChangePlanBatches,
  __changePlanTelit,
  __getChangePlan,
  __getChangePlanLog,
  __getRefillBatches,
  __refillCricket,
  __refillVerizon,
  __getRefill,
  __getRefillLog,
  __getTelitExportList,
  __getTelitExportFile,
  __telit_export,
  __getZipCheckList,
  __getZipCheckFile,
  __zipCheck,
  __checkStatus,
  __terminateProcess,
  __getSparqSimGroups,
  __getCredential,
  __updateCredential,
  __portin,
  __getPortinBatches,
  __portinUltra,
  __getPortin,
  __getPortinLog,
  __fetchSimTelitInfo,
  __clientActivate,
  __clientChangeNumber,
  __clientDeactivate,
  __clientSimInfo,
  __getUserHistory,
  __reset,
  __resetAdminUltra,
  __resetAdminTelit,
  __getResetBatches,
  __getReset,
  __setResetComplete,
  __setResetFailed,
  __searchLineClient,
  __searchLine,
};
