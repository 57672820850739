import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Box,
  Chip,
  Table,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  TableContainer,
} from "@mui/material";

import Title from "../../../common/Title";
import ApiLogHistory from "../../../common/ApiLogHistory";

import useAxios from "../../../../hooks/useAxios";
import { __getActivateLog } from "../../../../api/sim";
import { getChipColor } from "../../../../constants/value";

const TelitActivateHistory = (props) => {
  const [activateHistory, setActivateHistory] = useState([]);
  const { activate_id } = useParams();

  const [response, call, loading] = useAxios({
    ...__getActivateLog(activate_id),
  });

  useEffect(() => {
    if (response && response.status) {
      setActivateHistory(response.data);
    }
  }, [response]);

  useEffect(() => {
    call();
  }, []);

  return loading ? (
    <Title content="Please wait..." variant="subtitle2" gutterBottom />
  ) : (
    <>
      <Box mb={2} pt={2}>
        <Typography variant="h5" align="left">
          Activate Log
        </Typography>
      </Box>

      {response && activateHistory.data ? (
        <>
          <Box
            sx={{ width: "100%", mb: 5 }}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">ICCID</TableCell>
                    <TableCell align="left">Batch ID</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">MSISDN</TableCell>
                    <TableCell align="left">Last Updated</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                      {activateHistory.data.iccid}
                    </TableCell>
                    <TableCell align="left">
                      <Link
                        underline="none"
                        to={`/telit/activate/batch/${activateHistory.data.batch_id}`}
                      >
                        {activateHistory.data.batch_id}
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      <Chip
                        label={activateHistory.data.status}
                        size="small"
                        color={getChipColor(activateHistory.data.status, true)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {activateHistory.data.msisdn}
                    </TableCell>
                    <TableCell align="left">
                      {activateHistory.data.last_updated &&
                        `${format(
                          new Date(activateHistory.data.last_updated),
                          "MMMM d, y hh:mm a"
                        )}`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <ApiLogHistory data={activateHistory.history.data} />
        </>
      ) : (
        <Box mb={2} pt={2}>
          <Typography variant="h5" align="left">
            No Data
          </Typography>
        </Box>
      )}
    </>
  );
};

export default TelitActivateHistory;
