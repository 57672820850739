import * as yup from "yup";
import { add } from "date-fns";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { LocalizationProvider } from "@mui/x-date-pickers";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Button, TextareaAutosize } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Title from "../../../common/Title";
import InputFieldBox from "../../../common/InputFieldBox";
import InputFieldAutoComplete from "../../../common/InputFieldAutoComplete";

import useAlert from "../../../../hooks/useAlert";
import useAxios from "../../../../hooks/useAxios";
import { __activateCricket, __getSparqSimGroups } from "../../../../api/sim";

const CricketActivateForm = () => {
  const [rawData, setRawData] = useState("");
  const [linesData, setLinesData] = useState([]);
  const [formData, setFormData] = useState(null);
  const [iqsimCallback, setIqsimCallback] = useState(false);
  const [validityType, setValidityType] = useState(null);
  const [validityDays, setValidityDays] = useState(0);
  const [validityDate, setValidityDate] = useState(new Date());
  const [validityTimestamp, setValidityTimestamp] = useState(null);
  const [simGroups, setSimGroups] = useState([{ id: null, name: "None" }]);

  const { setAlertMessage } = useAlert();
  const initialData = { lines: [] };
  const [response, call, loading] = useAxios({
    ...__activateCricket,
  });
  const [simGroupResponse, simGroupCall, simGroupLoading] = useAxios({
    ...__getSparqSimGroups,
  });
  const nav = useNavigate();

  const lineValidationSchema = yup.object({
    iccid: yup
      .string("iccid")
      .min(19, "Should be 19-20 digits long")
      .max(20, "Should be 19-20 digits long")
      .required(),
  });

  useEffect(() => {
    if (formData) {
      const requestBody = {
        callback_iqsim: iqsimCallback,
        ...formData,
        lines: [...linesData],
      };
      if (iqsimCallback && validityType && validityTimestamp) {
        requestBody.validity_date = validityTimestamp;
      }
      call({
        data: requestBody,
      });
    }
  }, [formData]);

  useEffect(() => {
    simGroupCall();
  }, []);

  useEffect(() => {
    formik.setErrors(validateFunction(rawData));
  }, [
    iqsimCallback,
    validityType,
    validityDate,
    validityDays,
    validityTimestamp,
  ]);

  useEffect(() => {
    if (validityType === "days" && validityDays) {
      setValidityDate(null);
      setValidityTimestamp(
        add(new Date().setHours(0, 0, 0, 0), { days: validityDays }).getTime() /
          1000
      );
    }
  }, [validityDays]);

  useEffect(() => {
    if (validityType === "date" && validityDate) {
      setValidityDays(null);
      setValidityTimestamp(validityDate.getTime() / 1000);
    }
  }, [validityDate]);

  useEffect(() => {
    if (simGroupResponse && simGroupResponse.status) {
      setSimGroups([{ id: null, name: "None" }, ...simGroupResponse.data]);
    }
  }, [simGroupResponse]);

  useEffect(() => {
    if (!response) return;
    if (response.status) {
      setAlertMessage({
        type: "success",
        message: "Cricket Activation queue started",
      });
      setLinesData([]);
      nav("/cricket/activate");
    }
    formik.setSubmitting(false);
  }, [response]);

  const validateFunction = (data) => {
    const errors = {};
    const formatedLines = [];

    if (!data) {
      return errors;
    }

    const lines = data.split("\n");
    lines.some((line, idx) => {
      const data = line.split(",");
      if (data.length !== 1) {
        errors.lines = `Invalid format in line ${idx + 1}: ${line}`;
        return true;
      }

      if (lines.indexOf(line) !== idx) {
        errors.lines = `Duplicate value in line ${idx + 1} `;
        return true;
      }

      try {
        const line_data = line.split(",");
        const obtainedData = { iccid: line_data[0].trim() };

        lineValidationSchema.validateSync(obtainedData);

        if (isNaN(obtainedData.iccid)) {
          throw new Error("Invalid Number");
        }
        formatedLines.push(obtainedData);
      } catch (err) {
        errors.lines = `Invalid format in line ${idx + 1}: ${line}`;
        return true;
      }

      return false;
    });
    if (!errors.lines) {
      if (formatedLines.length % 4 !== 0) {
        errors.lines = "Number of lines should be divisible by 4";
        return errors;
      }
      setLinesData(formatedLines);
    }

    if (
      validityType === "date" &&
      (!validityDate ||
        validityDate.getTime() <= new Date().setHours(0, 0, 0, 0))
    ) {
      errors.validityDate = "Select future date";
    } else if (
      validityType === "date" &&
      validityDate &&
      validityDate.getTime() > new Date().setHours(0, 0, 0, 0)
    ) {
      errors.validityDate = null;
    }

    if (validityType === "days" && (!validityDays || validityDays < 1)) {
      errors.validityDays = "Select valid days";
    } else if (validityType === "days" && validityDays && validityDays >= 1) {
      errors.validityDays = null;
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: { ...initialData },
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        obj[key] = values[key] || null;
        return obj;
      }, {});

      setFormData(requestData);
    },
    validateOnChange: true,
  });

  const handleParse = (inputFile) => {
    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      setRawData(target.result);
      formik.setErrors(validateFunction(target.result));
    };
    reader.readAsText(inputFile);
  };
  const handleFileChange = (e) => {
    const allowedExtensions = ["csv"];

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        formik.setErrors({
          ...formik.errors.lines,
          upload: "Please input a csv file",
        });
        return;
      }

      handleParse(inputFile);
    }
  };

  if (loading || simGroupLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <>
      <Box
        sx={{ width: "100%" }}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Title
          variant="h5"
          content="Cricket Activate new sims"
          sx={{ mb: 3 }}
          gutterBottom
        />
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <FormControl
              error={Boolean(formik.errors.callback_iqsim) ? true : false}
              sx={{ width: "100%" }}
            >
              <InputFieldBox
                isCheckbox={true}
                value={iqsimCallback}
                onChange={(event) => setIqsimCallback(event.target.checked)}
                label="Iqsim Callback"
              />

              {Boolean(formik.errors.callback_iqsim) ? (
                <FormHelperText id="callback_iqsim-error-text">
                  {formik.errors.callback_iqsim}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {iqsimCallback && (
            <>
              <Grid item xs={12} minWidth={350}>
                <FormControl
                  error={
                    formik.touched.sim_group_id &&
                    Boolean(formik.errors.sim_group_id)
                      ? true
                      : false
                  }
                  sx={{ width: "100%" }}
                >
                  <InputFieldAutoComplete
                    isSelect
                    options={[
                      ...simGroups.reduce((acc, simGroup) => {
                        const val = {
                          value: simGroup.id,
                          label: simGroup.name,
                        };
                        acc.push(val);
                        return acc;
                      }, []),
                    ]}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    name="sim_group_id"
                    label="Sim Group"
                    id="sim_group_id"
                    onChange={(e, value) =>
                      formik.setFieldValue("sim_group_id", value?.value || null)
                    }
                  />
                  {formik.touched.sim_group_id &&
                  Boolean(formik.errors.sim_group_id) ? (
                    <FormHelperText id="sim_group_id-error-text">
                      {formik.errors.sim_group_id}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} minWidth={350}>
                <FormControl
                  error={
                    formik.touched.validityType &&
                    Boolean(formik.errors.validityType)
                      ? true
                      : false
                  }
                  sx={{ width: "100%" }}
                >
                  <InputFieldAutoComplete
                    isSelect
                    options={[
                      { value: null, label: "None" },
                      { value: "days", label: "Days" },
                      { value: "date", label: "Date" },
                    ]}
                    isOptionEqualToValue={(option, value) =>
                      option.value === validityType
                    }
                    name="validityType"
                    label="Validity Date"
                    id="validityType"
                    onChange={(e, value) => setValidityType(value?.value)}
                  />
                  {formik.touched.validityType &&
                  Boolean(formik.errors.validityType) ? (
                    <FormHelperText id="validityType-error-text">
                      {formik.errors.validityType}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
              {validityType === "days" && (
                <Grid item xs={12} minWidth={350}>
                  <FormControl
                    error={Boolean(formik.errors.validityDays) ? true : false}
                    sx={{ width: "100%" }}
                  >
                    <InputFieldBox
                      name="validityDays"
                      label="Validity Days"
                      id="validityDays"
                      type="number"
                      value={validityDays}
                      onChange={(e, value) => setValidityDays(e.target.value)}
                    />
                    {Boolean(formik.errors.validityDays) ? (
                      <FormHelperText id="validityDays-error-text">
                        {formik.errors.validityDays}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
              )}
              {validityType === "date" && (
                <Grid item xs={12} minWidth={350}>
                  <FormControl
                    error={
                      formik.touched.validityDate &&
                      Boolean(formik.errors.validityDate)
                        ? true
                        : false
                    }
                    sx={{ width: "100%" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        id="validityDate"
                        value={validityDate}
                        disablePast
                        onChange={(value, context) => {
                          setValidityDate(value);
                        }}
                        slotProps={{
                          textField: {
                            helperText: formik.errors.validityDate,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12}>
            <FormControl
              error={Boolean(formik.errors.upload) ? true : false}
              sx={{ width: "100%" }}
            >
              <Button
                component="label"
                variant="outlined"
                startIcon={<UploadFileIcon />}
                sx={{ marginRight: "1rem" }}
              >
                Upload CSV
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
              {Boolean(formik.errors.upload) ? (
                <FormHelperText id="upload-error-text">
                  {formik.errors.upload}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="lines">
              {"Add in the format"}
              <br></br>
              {"ICCID"}
              <br></br>
              {"ICCID"}
              <br></br>
            </InputLabel>
            <FormControl
              error={Boolean(formik.errors.lines) ? true : false}
              sx={{ width: "100%" }}
            >
              <TextareaAutosize
                multiline
                rows={6}
                aria-label="minimum height"
                placeholder={`Add in the format\nICCID\nICCID`}
                style={{ minWidth: 550 }}
                name="lines"
                minRows={5}
                id="lines"
                value={rawData}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const data = e.target.value;
                  setRawData(data);
                  formik.setErrors(validateFunction(data));
                }}
              />
              {Boolean(formik.errors.lines) ? (
                <FormHelperText id="lines-error-text">
                  {formik.errors.lines}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

          <Grid
            container
            sx={{ mt: 3 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <LoadingButton
                style={{ marginRight: "5px" }}
                disabled={
                  formik.errors.lines ||
                  formik.errors.validityDate ||
                  formik.errors.validityDays ||
                  !linesData.length ||
                  (iqsimCallback && formik.values.sim_group_id === undefined)
                }
                onClick={() =>
                  !loading && !formik.isSubmitting && formik.submitForm()
                }
                size="large"
                loading={loading}
                variant="contained"
              >
                {"Cricket Activate new SIMs"}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CricketActivateForm;
