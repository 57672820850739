import React, { useEffect } from "react";

import { Button, Stack } from "@mui/material";

import useAxios from "../../hooks/useAxios";
import useAlert from "../../hooks/useAlert";
import { __terminateProcess } from "../../api/sim";

const TerminateBatch = ({ batch_id, operation }) => {
  const { setAlertMessage } = useAlert();
  const [response, call, loading] = useAxios({
    ...__terminateProcess(batch_id),
  });

  useEffect(() => {
    if (response && response.status) {
      setAlertMessage({
        type: "success",
        message: "Batch is queued to be stopped",
      });
    }
  }, [response]);

  return (
    <Stack spacing={2} direction="row">
      <Button
        variant="contained"
        color="error"
        disabled={loading}
        onClick={() =>
          call({ params: {operation: operation } })
        }
      >
        Terminate Batch
      </Button>
    </Stack>
  );
};

export default TerminateBatch;
