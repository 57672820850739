import * as yup from "yup";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, OutlinedInput } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import Title from "../../common/Title";

import useAlert from "../../../hooks/useAlert";
import useAxios from "../../../hooks/useAxios";
import { CLIENTS } from "../../../constants/value";
import { __getCredential, __updateCredential } from "../../../api/sim";

const SparqCredentialForm = () => {
  const [credential, setCredential] = useState(null);
  const [formData, setFormData] = useState(null);

  const { setAlertMessage } = useAlert();
  const [response, call, loading] = useAxios({
    ...__getCredential(CLIENTS.sparq),
  });
  const [updateResponse, updateCall, updateLoading] = useAxios({
    ...__updateCredential(CLIENTS.sparq),
  });
  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (formData) {
      updateCall({
        data: formData,
      });
    }
  }, [formData]);

  useEffect(() => {
    if (!updateResponse) return;
    if (updateResponse.status) {
      setCredential(updateResponse.data);
      setAlertMessage({ type: "success", message: "Update successful" });
    }
  }, [updateResponse]);

  useEffect(() => {
    if (!response) return;
    if (response.status) {
      setCredential(response.data);
      formik.setValues({
        api_key: response.data.api_key,
      });
    }
  }, [response]);

  const initialData = {
    api_key: "",
  };

  const validationSchema = yup.object({
    api_key: yup.string("api_key"),
  });

  const formik = useFormik({
    initialValues: { ...initialData },
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        obj[key] = values[key] || null;
        return obj;
      }, {});

      setFormData(requestData);
    },
    validationSchema: validationSchema,
    validateOnChange: true,
  });

  if (loading || updateLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <>
      <Box
        sx={{ width: "100%" }}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Title
          variant="h5"
          content="Sparq Credentials"
          sx={{ mb: 3 }}
          gutterBottom
        />
        <Grid
          sx={{ flexGrow: 1, width: "100%" }}
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs sx={{ minWidth: 400 }}>
            <FormControl
              error={
                formik.touched.api_key && Boolean(formik.errors.api_key)
                  ? true
                  : false
              }
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="api_key">Api Key</InputLabel>
              <OutlinedInput
                name="api_key"
                label="Api Key"
                id="api_key"
                value={formik.values.api_key}
                onChange={formik.handleChange}
              />
              {formik.touched.api_key && Boolean(formik.errors.api_key) ? (
                <FormHelperText id="api_key-error-text">
                  {formik.errors.api_key}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

          <Grid
            container
            sx={{ mt: 3 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <LoadingButton
                style={{ marginRight: "5px" }}
                disabled={loading || updateLoading}
                onClick={() =>
                  !loading && !updateLoading && formik.submitForm()
                }
                size="large"
                loading={loading}
                variant="contained"
              >
                {"Update"}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SparqCredentialForm;
