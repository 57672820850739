const __fetchUsers = {
  method: "GET",
  url: "user/",
};

const __fetchUserDetails = (id) => ({
  method: "GET",
  url: `user/${id}`,
});

const __updateUserDetails = (id) => ({
  method: "PUT",
  url: `user/${id}`,
});

const __createUser = {
  method: "POST",
  url: `user/`,
};

export { __fetchUsers, __fetchUserDetails, __updateUserDetails, __createUser };
