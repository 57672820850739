import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../../hooks/useAuth";

const Home = () => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (user.admin) {
    return <Navigate to="/ultra" />;
  }

  return <Navigate to="/client" />;
};

export default Home;
