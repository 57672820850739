import debounce from "lodash.debounce";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";

import ResetList from "./ResetList";
import Title from "../../common/Title";

import useAuth from "../../../hooks/useAuth";
import { copyData } from "../../../utils/copy";
import useAxios from "../../../hooks/useAxios";
import useAlert from "../../../hooks/useAlert";
import { CLIENTS } from "../../../constants/value";
import { __getReset, __setResetComplete, __setResetFailed } from "../../../api/sim";

const ResetBatch = () => {
  const { batch_id } = useParams();
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const nav = useNavigate();

  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 20000,
    total: 1,
  });
  const [response, call, loading] = useAxios({ ...__getReset });
  const [completeResponse, completeCall, completeLoading] = useAxios({
    ...__setResetComplete(batch_id),
  });
  const [failedResponse, failedCall, failedLoading] = useAxios({
    ...__setResetFailed(batch_id),
  });
  const { setAlertMessage } = useAlert();

  const debouncedFetchData = debounce((query) => {
    call(query);
  }, 500);

  useEffect(() => {
    debouncedFetchData({
      params: {
        page_size: 20000,
        batch_id: batch_id,
      },
    });

    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setMetaData({
        page: response.data.page,
        page_size: response.data.page_size,
        total: response.data.total,
      });
      setData(response.data.data);

    }
  }, [response]);

  useEffect(() => {
    if (completeResponse && completeResponse.status) {
      setAlertMessage({
        type: "success",
        message: "Marked Complete",
      });
      debouncedFetchData({
        params: {
          page_size: 20000,
          batch_id: batch_id,
        },
      });
    }
  }, [completeResponse]);

  useEffect(() => {
    if (failedResponse && failedResponse.status) {
      setAlertMessage({
        type: "success",
        message: "Marked Failed",
      });
      debouncedFetchData({
        params: {
          page_size: 20000,
          batch_id: batch_id,
        },
      });
    }
  }, [failedResponse]);

  return (
    <Box sx={{ width: "100%" }}>
      <Title
        variant="h5"
        content={`Reset Batch ID: ${batch_id}`}
        gutterBottom
      />
      {user &&
        user.admin &&
        response &&
        data.length &&
        data[0]?.status === "submitted" &&
        data[0]?.client === CLIENTS.telit && (
          <Grid container justifyContent="flex-start" spacing={2}>
            <Grid item sx={{ mb: "10px" }}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading || completeLoading || failedLoading}
                  onClick={() => completeCall()}
                >
                  Mark Complete
                </Button>
              </Stack>
            </Grid>
            <Grid item sx={{ mb: "10px" }}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading || completeLoading || failedLoading}
                  onClick={() => failedCall()}
                >
                  Mark Failed
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
      {response ? (
        <>
          <ResetList
            data={data}
            loading={loading}
            meta={{ ...metaData, page_size: 100 }}
            client={true}
            handleColumnClick={(key) => copyData(data, [key])}
          />
        </>
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default ResetBatch;
