import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";

import Title from "../../common/Title";
import BatchList from "../../common/BatchList";

import useAxios from "../../../hooks/useAxios";
import { __getRefillBatches } from "../../../api/sim";

const Refill = ({client}) => {
  const [data, setData] = useState([]);
  const nav = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 100,
    total: 1,
  });
  const [response, call, loading] = useAxios({ ...__getRefillBatches });

  const debouncedFetchData = debounce((query) => {
    call(query);
  }, 500);

  useEffect(() => {
    setMetaData({
      page: 1,
      page_size: 100,
      total: 1,
    });
    setData([]);
  }, [client]);

  useEffect(() => {
    debouncedFetchData({
      params: {
        page: currentPage,
        page_size: pageSize,
        client
      },
    });

    return () => {
      debouncedFetchData.cancel();
    };
  }, [currentPage, pageSize, client]);

  useEffect(() => {
    if (response && response.status) {
      setMetaData({
        page: response.data.page,
        page_size: response.data.page_size,
        total: response.data.total,
      });
      setData(response.data.data);
    }
  }, [response]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setCurrentPage(1);
    setPageSize(newPageSize);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" sx={{ textTransform: "capitalize" }}
        content={`${client ? client : ""} Refill`} gutterBottom />
      {response && (
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="primary"
                onClick={() => nav("form")}
              >
                {`Refill SIMs`}
              </Button>
            </Stack>
          </Grid>
          
        </Grid>
      )}
      {response ? (
        <>
          <BatchList
            data={data}
            loading={loading}
            meta={metaData}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        </>
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default Refill;
