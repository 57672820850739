import React from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { Chip } from "@mui/material";

import DataTable from "../../common/DataTable";
import { getChipColor } from "../../../constants/value";

const SearchList = ({ data, meta, loading, ...props }) => {
  const nav = useNavigate();
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      sortable: false,
    },
    {
      field: "iccid",
      headerName: "ICCID",
      minWidth: 200,
      flex: 2,
      sortable: false,
    },
    {
      field: "batch_id",
      headerName: "Batch ID",
      minWidth: 300,
      flex: 3,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          size="small"
          color={getChipColor(params.row.status)}
        />
      ),
    },
    {
      field: "created_at",
      headerName: "Created At",
      minWidth: 200,
      filterable: false,
      flex: 3,
      sortable: false,
      renderCell: (params) => (
        <div>
          {params.row.created_at &&
            `${format(new Date(params.row.created_at), "MMMM d, y hh:mm a")}`}
        </div>
      ),
    },
    {
      field: "last_updated",
      headerName: "Last Updated",
      minWidth: 200,
      filterable: false,
      flex: 3,
      sortable: false,
      renderCell: (params) => (
        <div>
          {params.row.last_updated &&
            `${format(new Date(params.row.last_updated), "MMMM d, y hh:mm a")}`}
        </div>
      ),
    },
  ];

  return (
    <DataTable
      handleRowClick={(params) => nav(`batch/${params.row.batch_id}`)}
      disableColumnFilter
      loading={loading}
      data={data}
      columns={columns}
      metaData={meta}
      {...props}
    />
  );
};

export default SearchList;
