import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";

import Search from "../Search/Search";
import Title from "../../common/Title";
import BatchList from "../../common/BatchList";

import useAxios from "../../../hooks/useAxios";
import { OPERATIONS } from "../../../constants/value";
import { __getUnpauseBatches } from "../../../api/sim";

const Unpause = ({ client }) => {
  const [data, setData] = useState([]);
  const nav = useNavigate();

  const [searchActive, setSearchActive] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 100,
    total: 1,
  });
  const [response, call, loading] = useAxios({ ...__getUnpauseBatches });

  const debouncedFetchData = debounce((query) => {
    call(query);
  }, 500);

  useEffect(() => {
    setMetaData({
      page: 1,
      page_size: 100,
      total: 1,
    });
    setData([]);
  }, [client]);

  useEffect(() => {
    debouncedFetchData({
      params: {
        page: currentPage,
        page_size: pageSize,
        client,
      },
    });

    return () => {
      debouncedFetchData.cancel();
    };
  }, [currentPage, pageSize, client]);

  useEffect(() => {
    if (response && response.status) {
      setMetaData({
        page: response.data.page,
        page_size: response.data.page_size,
        total: response.data.total,
      });
      setData(response.data.data);
    }
  }, [response]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setCurrentPage(1);
    setPageSize(newPageSize);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Title
        variant="h5"
        sx={{ textTransform: "capitalize" }}
        content={`${client ? client : ""} Unpause`}
        gutterBottom
      />

      <Search
        key={`${client}-unpause`}
        client={client}
        operation={OPERATIONS.unpause}
        searchActive={searchActive}
        setSearchActive={setSearchActive}
      />

      {/* if search not active show as desired */}
      {!searchActive &&
        (response ? (
          <>
            <Grid container justifyContent="flex-start" spacing={2}>
              <Grid item>
                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => nav("form")}
                  >
                    Unpause Sims
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            <BatchList
              data={data}
              loading={loading}
              meta={metaData}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
            />
          </>
        ) : (
          <Title content="Please wait..." variant="subtitle2" gutterBottom />
        ))}
    </Box>
  );
};

export default Unpause;
