import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Box,
  Chip,
  Table,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  TableContainer,
} from "@mui/material";

import Title from "../../../common/Title";
import ApiLogHistory from "../../../common/ApiLogHistory";

import useAxios from "../../../../hooks/useAxios";
import { __getUnpauseLog } from "../../../../api/sim";
import { getChipColor } from "../../../../constants/value";

const UnpauseHistory = (props) => {
  const [unpauseHistory, setUnpauseHistory] = useState([]);
  const { unpause_id } = useParams();

  const [response, call, loading] = useAxios({
    ...__getUnpauseLog(unpause_id),
  });

  useEffect(() => {
    if (response && response.status) {
      setUnpauseHistory(response.data);
    }
  }, [response]);

  useEffect(() => {
    call();
  }, []);

  return loading ? (
    <Title content="Please wait..." variant="subtitle2" gutterBottom />
  ) : (
    <>
      <Box mb={2} pt={2}>
        <Typography variant="h5" align="left">
          Unpause Log
        </Typography>
      </Box>

      {response && unpauseHistory.data ? (
        <>
          <Box
            sx={{ width: "100%", mb: 5 }}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">ICCID</TableCell>
                    <TableCell align="left">Batch ID</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Last Updated</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                      {unpauseHistory.data.iccid}
                    </TableCell>
                    <TableCell align="left">
                      <Link
                        underline="none"
                        to={`/ultra/unpause/batch/${unpauseHistory.data.batch_id}`}
                      >
                        {unpauseHistory.data.batch_id}
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      <Chip
                        label={unpauseHistory.data.status}
                        size="small"
                        color={getChipColor(unpauseHistory.data.status)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {unpauseHistory.data.last_updated &&
                        `${format(
                          new Date(unpauseHistory.data.last_updated),
                          "MMMM d, y hh:mm a"
                        )}`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <ApiLogHistory data={unpauseHistory.history.data} />
        </>
      ) : (
        <Box mb={2} pt={2}>
          <Typography variant="h5" align="left">
            No Data
          </Typography>
        </Box>
      )}
    </>
  );
};

export default UnpauseHistory;
