const getPropByString = (obj, propString) => {
  if (!propString) return obj;

  var prop,
    props = propString.split(".");

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    var candidate = obj[prop];
    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
};

const copyData = (data, keys) => {
  if (!data?.length) {
    navigator.clipboard.writeText("");
    return;
  }
  const copyText = [
    ...data.map((item) => keys.map((key) => getPropByString(item, key))),
  ]
    .map((e) => e.join(", "))
    .reverse()
    .join("\n");

  navigator.clipboard.writeText(copyText);
};

export { copyData };
