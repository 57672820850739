import fileDownload from "js-file-download";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import { Box} from "@mui/material";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Title from "../../common/Title";
import InputFieldAutoComplete from "../../common/InputFieldAutoComplete";

import useAuth from "../../../hooks/useAuth";
import useAxios from "../../../hooks/useAxios";
import { __fetchUsers } from "../../../api/user";
import { __getUserHistory } from "../../../api/sim";

const HistoryForm = () => {
  const { user } = useAuth();
  const nav = useNavigate();

  if (!user.admin) {
    nav("/");
  }

  const [users, setUsers] = useState([]);

  const [userId, setUserId] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const [userResponse, userCall, userLoading] = useAxios({ ...__fetchUsers });
  const [exportResponse, exportCall, exportLoading] = useAxios({
    ...__getUserHistory,
  });

  useEffect(() => {
    userCall();
  }, []);

  useEffect(() => {
    if (userResponse && userResponse.status) {
      setUsers(userResponse.data.data);
    }
  }, [userResponse]);

  useEffect(() => {
    if (exportResponse && exportResponse.status) {
      fileDownload(exportResponse.data, `user_history_export.csv`);
      nav("/user");
    }
  }, [exportResponse]);

  if (exportLoading || userLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  const handleSubmit = () => {
    const finalEndDate = new Date(endDate);
    finalEndDate.setHours(23, 59, 59, 59);

    exportCall({
      data: {
        user_id: userId,
        start_date: startDate,
        end_date: finalEndDate,
      },
      responseType: "blob",
    });
  };

  return (
    <>
      <Box
        sx={{ width: "100%", mb: 5 }}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Title
          variant="h5"
          content={"Export User History"}
          sx={{ mb: 3 }}
          gutterBottom
        />
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
          maxWidth={350}
        >
          <Grid item xs={12} style={{ width: 350 }}>
            <FormControl error={false} sx={{ width: "100%" }}>
              <InputFieldAutoComplete
                isSelect
                options={[
                  ...users.map((user) => user.id).filter((id) => id !== 1),
                ]}
                name="user_id"
                label="User"
                id="user_id"
                getOptionLabel={(option) =>
                  users.reduce((label, val) => {
                    if (`${val.id}` === `${option}`) return val.username;
                    return label;
                  }, "")
                }
                value={userId}
                onChange={(_, val) => setUserId(val)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ width: 350 }}>
            <FormControl error={false} sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  id="start_date"
                  label="Start Date"
                  value={startDate}
                  maxDate={new Date()}
                  onChange={(val) => setStartDate(val)}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ width: 350 }}>
            <FormControl
              error={
                startDate && endDate && endDate <= startDate ? true : false
              }
              sx={{ width: "100%" }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  id="end_date"
                  label="End Date"
                  onChange={(val) => setEndDate(val)}
                  minDate={startDate ? startDate : null}
                  maxDate={new Date()}
                />
              </LocalizationProvider>
              {startDate && endDate && endDate <= startDate ? (
                <FormHelperText id="end_date-error-text">
                  Select valid date
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid
            container
            sx={{ mt: 3 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <LoadingButton
                style={{ marginRight: "5px" }}
                disabled={!userId || !startDate || !endDate}
                onClick={() => !userLoading && !exportLoading && handleSubmit()}
                size="large"
                loading={exportLoading}
                variant="contained"
              >
                Export
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                style={{ marginRight: "5px" }}
                size="large"
                onClick={() => nav("/user")}
                loading={exportLoading}
                variant="contained"
              >
                Cancel
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HistoryForm;
