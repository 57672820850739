import { format } from "date-fns";

import styled from "@emotion/styled";
import { DataGrid } from "@mui/x-data-grid";
import { alpha, gridClasses, Chip, Box } from "@mui/material";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${gridClasses.row}`]: {
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      cursor: "pointer",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const columns = [
  { field: "id", headerName: "ID", flex: 1 },
  {
    field: "success",
    headerName: "Success",
    flex: 1,
    renderCell: (params) => (
      <Chip
        label={params.row.success.toString()}
        size="small"
        color={params.row.success ? "success" : "error"}
      />
    ),
  },
  { field: "payload", headerName: "Payload", flex: 3 },
  { field: "response", headerName: "Response", flex: 3 },
  {
    field: "request_time",
    headerName: "Request Time",
    minWidth: 200,
    flex: 2,
    renderCell: (params) => (
      <Box >
        {format(new Date(params.row.request_time), "MMMM d, y hh:mm a")}
      </Box>
    ),
  },
];

const ApiLogHistory = ({ data }) => {
  return (
    <StripedDataGrid
      autoHeight
      rows={data}
      columns={columns}
      disableSelectionOnClick
      getRowHeight={() => "auto"}
      sx={{
        [`& .${gridClasses.cell}`]: {
          py: 4,
        },
      }}
    />
  );
};

export default ApiLogHistory;
